import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { tsExpressionWithTypeArguments } from "@babel/types";

class TextScramble {
    constructor(el) {
        this.el = el;
        this.chars = "!<>-_—=+*^?#________rk";
        this.update = this.update.bind(this);
    }
    setText(newText) {
        const oldText = this.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || "";
            const to = newText[i] || "";
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
    }
    update() {
        let output = "";
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i];
            if (this.frame >= end) {
                complete++;
                output += to;
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar();
                    this.queue[i].char = char;
                }
                output += `<span class="dud">${char}</span>`;
            } else {
                output += from;
            }
        }
        this.el.innerHTML = output;
        if (complete === this.queue.length) {
            this.resolve();
        } else {
            this.frameRequest = requestAnimationFrame(this.update);
            this.frame++;
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
}

export default class Mobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: this.props.pages,
            parts: this.props.parts,
            clients: this.props.clients,
            contact: this.props.contact,
            mention: this.props.mention,
            socials: this.props.socials,
            titre: this.props.pages[0].title,
            baseline: this.props.pages[0].baseline,
            block_01: this.props.pages[0].block_01,
            block_02: this.props.pages[0].block_02,
            pageNb: 0,
            phrases: [],
            counter: 0,
            size: 20,
            openMenu: false,
            nom: "",
            prenom: "",
            email: "",
            sujet: "",
            message: "",
            flash: "",
            flashBool: false,
            flashSuccess: false,
            width: window.innerWidth,
            clienXStart: null,
        };
        this.page = this.page.bind(this);
        this.firstPage = this.firstPage.bind(this);

        this.handlePrevScroll = this.handlePrevScroll.bind(this);
        this.handleNextScroll = this.handleNextScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.openContact = this.openContact.bind(this);
        this.closeContact = this.closeContact.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleSlideClient = this.handleSlideClient.bind(this);
        this.hanldeSlideContact = this.hanldeSlideContact.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
    }
    componentDidMount() {
        //screen.orientation.lock("portrait");
        let menu = document.querySelector(".itemMenu");
        let { phrases, pages, counter, width } = this.state;
        const blockP = document.querySelectorAll(".block_1 p");
        const el = document.querySelector(".content h2");
        const fx = new TextScramble(el);
        phrases = ["retrokube", "retrokube"];
        menu.style.right = "-" + width + "px";
        let itemContact = document.querySelector(".itemContact");
        itemContact.style.width = width - 20 + "px";
        itemContact.style.left = "-" + (width - 20) + "px";
        setTimeout(() => {
                blockP.forEach((value, i) => {
                    setTimeout(() => {
                        value.style.opacity = "1";
                        value.classList.add(
                            "animate__animated",
                            "animate__fadeInRight"
                        );
                    }, i * 500);
                });
            counter = (counter + 1) % phrases.length;
            this.setState(
                {
                    counter,
                },
                () => {
                    phrases = [];
                    pages.map((value) => {
                        phrases.push(value.title);
                    });
                    let links = document.querySelectorAll(
                        "#mobile .item .content .wrap_block.wrap .block_1 p"
                    );
                    links.forEach((value, index) => {
                        value.addEventListener("click", () => {
                            this.page(index + 1);
                        });
                    });
                    this.setState({ phrases });
                }
            );
        }, 500);
    }
    openMenu() {
        const { width } = this.state;
        let menu = document.querySelector(".itemMenu");
        let menuBlock = document.querySelector(".itemMenu .block_menu");
        menu.style.right = "0";
        menuBlock.style.opacity = "1";
    }
    closeMenu() {
        const { width } = this.state;
        let menu = document.querySelector(".itemMenu");
        let menuBlock = document.querySelector(".itemMenu .block_menu");
        menuBlock.style.opacity = "0";
        menu.style.right = "0";
        menu.style.right = "-" + width + "px";
    }
    openContact() {
        const { width } = this.state;
        const body = document.querySelector("#mobile");
        let contact = document.querySelector(".itemContact");
        let contactBlock = document.querySelector(
            ".itemContact .block_contact_1"
        );
        contact.style.left = "0";
        contact.style.opacity = "1";
        body.style.background = "#EF6949";
        contactBlock.style.opacity = "1";
    }
    closeContact() {
        const { pageNb } = this.state;
        const { width } = this.state;
        this.setState(
            { flashBool: false, flashSuccess: false, flash: "" },
            () => {
                const body = document.querySelector("#mobile");
                let contact = document.querySelector(".itemContact");
                let contactBlock = document.querySelector(
                    ".itemContact .block_contact_1"
                );
                contact.style.opacity = "0";
                contact.style.left = "-" + width + "px";
                contactBlock.style.opacity = "0";
            }
        );
    }
    page(id) {
            
        let { phrases, counter, pages, pageNb, width } = this.state;

        pageNb = id;
        counter = id;

        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".block_1 p");

        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".bggruge");

        switch (pageNb) {
            case 0:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 1:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 2:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 3:
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 4:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 5:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 6:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 7:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                break;
            default:
                break;
        }
        el2.style.opacity = "0";
        if (pageNb == 0) {
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                        break;
                    case 1:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 2:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                        break;
                    case 3:
                        itembg.style.backgroundImage =
                        "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 4:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;
                    case 5:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 6:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 7:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;

                    default:
                        break;
                }
            }, 1000);
        } else {
            blockP.forEach((value, i) => {
                setTimeout(() => {
                    value.classList.remove(
                        "animate__animated",
                        "animate__fadeInRight"
                    );
                    value.classList.add(
                        "animate__animated",
                        "animate__fadeOutLeft"
                    );
                    setTimeout(() => {
                        switch (pageNb) {
                            case 0:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                                break;
                            case 1:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                                break;
                            case 2:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                                break;
                            case 3:
                                itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                break;
                            case 4:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                                break;
                            case 5:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                break;
                            case 6:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                                break;
                            case 7:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                                break;

                            default:
                                break;
                        }
                    }, 1000);
                    setTimeout(() => {
                        value.style.opacity = "0";
                        itembgAfter.style.opacity = "0";
                        itembgAfter.style.animation = "unset";
                    }, 2000);
                }, i * 200);
            });
        }
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 =
                                document.querySelectorAll(".block_1 p");

                            blockP2.forEach((value, i) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(i + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                }, i * 500);
                            });
                        }
                    );
                }, 1000);
            });
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "54px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "13px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "66px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "17px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "0";
                            el.style.textAlign = "center";
                            el2.style.fontSize = "23px";
                            el2.style.textAlign = "center";
                            el2.style.marginRight = "24px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "150px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "160px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "215px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "52px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 1:
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.lineHeight = "50px";
                            el.style.fontSize = "40px";
                            el2.style.fontSize = "16px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "49px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "28px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "100px";
                            el.style.lineHeight = "120px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "135px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "150px";
                            el2.style.position = "relative";
                            el2.style.left = "40px";
                            el2.style.fontSize = "50px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "55px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "28px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "105px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "55px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "125px";
                            el.style.lineHeight = "135px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "165px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "175px";
                            el2.style.position = "relative";
                            el2.style.left = "60px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "85px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "105px";
                            el2.style.fontSize = "26px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "21px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "145px";
                            el.style.lineHeight = "155px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "40px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "235px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "265px";
                            el.style.lineHeight = "285px";
                            el2.style.fontSize = "75px";
                        }
                        break;
                    case 4:
                        
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "48px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "80px";
                            el.style.lineHeight = "95px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "105px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "140px";
                            el.style.lineHeight = "155px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "58px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el.style.marginBottom = "0";
                            el2.style.fontSize = "25px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "95px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "110px";
                            el.style.lineHeight = "125px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                            el2.style.fontWeight = "400";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "155px";
                            el.style.lineHeight = "170px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "60px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "215px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "85px";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "23px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "110px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "35px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "140px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "180px";
                            el.style.lineHeight = "195px";
                            el2.style.fontSize = "65px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 360 && width < 375) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 414 && width < 768) {
                            el.style.fontSize = "25px";
                            el.style.lineHeight = "60px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "30px";
                            el.style.lineHeight = "120px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "150px";
                            el.style.marginBottom = "40px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);
    }
    firstPage() {
        this.page(0);
    }
    handleNextClick(direction){
        if(direction === "prev"){
            this.handlePrevScroll();
        }else{
            this.handleNextScroll();
        }
    }
    handleNextScroll(e) {
            
        let { phrases, counter, pages, pageNb, width } = this.state;
        if (pageNb === 7) {
            pageNb = 0;
        } else {
            pageNb = pageNb + 1;
        }

        if (pageNb === 0) {
            counter = 0;
        } else if (pageNb === 1) {
            counter = 1;
        } else if (pageNb === 2) {
            counter = 2;
        } else if (pageNb === 3) {
            counter = 3;
        } else if (pageNb === 4) {
            counter = 4;
        } else if (pageNb === 5) {
            counter = 5;
        } else if (pageNb === 6) {
            counter = 6;
        } else if (pageNb === 7) {
            counter = 7;
        }
        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".content .block_1 p");
        const block1 = document.querySelector(".content .block_1");

        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".bggruge");

        switch (pageNb) {
            case 0:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 1:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 2:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 3:
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 4:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 5:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 6:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 7:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                break;
            default:
                break;
        }
        el2.style.opacity = "0";
        if (pageNb == 0) {
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                        break;
                    case 1:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 2:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                        break;
                    case 3:
                        itembg.style.backgroundImage =
                        "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 4:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;
                    case 5:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 6:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 7:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;

                    default:
                        break;
                }
            }, 1000);
        } else {
            blockP.forEach((value, i) => {
                setTimeout(() => {
                    value.classList.remove(
                        "animate__animated",
                        "animate__fadeInRight"
                    );
                    value.classList.add(
                        "animate__animated",
                        "animate__fadeOutLeft"
                    );
                    setTimeout(() => {
                        switch (pageNb) {
                            case 0:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                                break;
                            case 1:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                                break;
                            case 2:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                                break;
                            case 3:
                                itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                break;
                            case 4:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                                break;
                            case 5:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                break;
                            case 6:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                                break;
                            case 7:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                                break;

                            default:
                                break;
                        }
                    }, 1000);
                    setTimeout(() => {
                        value.style.opacity = "0";
                        itembgAfter.style.opacity = "0";
                        itembgAfter.style.animation = "unset";
                    }, 2000);
                }, i * 200);
            });
        }
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 =
                                document.querySelectorAll(".block_1 p");

                            blockP2.forEach((value, i) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(i + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                }, i * 500);
                            });
                        }
                    );
                }, 1000);
            });
            setTimeout(() => {

                switch (pageNb) {
                    case 0:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "54px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "13px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "66px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "17px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "0";
                            el.style.textAlign = "center";
                            el2.style.fontSize = "23px";
                            el2.style.textAlign = "center";
                            el2.style.marginRight = "24px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "150px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "160px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "215px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "52px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 1:
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.lineHeight = "50px";
                            el.style.fontSize = "40px";
                            el2.style.fontSize = "16px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "49px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "28px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "100px";
                            el.style.lineHeight = "120px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "135px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "150px";
                            el2.style.position = "relative";
                            el2.style.left = "40px";
                            el2.style.fontSize = "50px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "55px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "28px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "105px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "55px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "125px";
                            el.style.lineHeight = "135px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "165px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "175px";
                            el2.style.position = "relative";
                            el2.style.left = "60px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "85px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "105px";
                            el2.style.fontSize = "26px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "21px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "145px";
                            el.style.lineHeight = "155px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "40px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "235px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "265px";
                            el.style.lineHeight = "285px";
                            el2.style.fontSize = "75px";
                        }
                        break;
                    case 4:
                        
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "48px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "80px";
                            el.style.lineHeight = "95px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "105px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "140px";
                            el.style.lineHeight = "155px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "58px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el.style.marginBottom = "0";
                            el2.style.fontSize = "25px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "95px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "110px";
                            el.style.lineHeight = "125px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                            el2.style.fontWeight = "400";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "155px";
                            el.style.lineHeight = "170px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "60px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "215px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "85px";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "23px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "110px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "35px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "140px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "180px";
                            el.style.lineHeight = "195px";
                            el2.style.fontSize = "65px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 360 && width < 375) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 414 && width < 768) {
                            el.style.fontSize = "25px";
                            el.style.lineHeight = "60px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "30px";
                            el.style.lineHeight = "120px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "150px";
                            el.style.marginBottom = "40px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);

    }
    handlePrevScroll(e) {
            
        let { phrases, counter, pages, pageNb, width } = this.state;
        if (pageNb === 0) {
            pageNb = 7;
        } else {
            pageNb = pageNb - 1;
        }

        if (pageNb === 0) {
            counter = 0;
        } else if (pageNb === 1) {
            counter = 1;
        } else if (pageNb === 2) {
            counter = 2;
        } else if (pageNb === 3) {
            counter = 3;
        } else if (pageNb === 4) {
            counter = 4;
        } else if (pageNb === 5) {
            counter = 5;
        } else if (pageNb === 6) {
            counter = 6;
        } else if (pageNb === 7) {
            counter = 7;
        }
        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".block_1 p");

        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".bggruge");

        switch (pageNb) {
            case 0:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 1:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 2:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 3:
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 4:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 5:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 6:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                break;
            case 7:
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                break;
            default:
                break;
        }

        el2.style.opacity = "0";
        blockP.forEach((value, i) => {
            setTimeout(() => {
                value.classList.remove(
                    "animate__animated",
                    "animate__fadeInRight"
                );
                value.classList.add(
                    "animate__animated",
                    "animate__fadeOutLeft"
                );
                setTimeout(() => {
                    switch (pageNb) {
                        case 0:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                            break;
                        case 1:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                            break;
                        case 2:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                            break;
                        case 3:
                            itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                            break;
                        case 4:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                            break;
                        case 5:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                            break;
                        case 6:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                            break;
                        case 7:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 0px 560px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                            break;

                        default:
                            break;
                    }
                }, 1000);
                setTimeout(() => {
                    value.style.opacity = "0";
                    itembgAfter.style.opacity = "0";
                    itembgAfter.style.animation = "unset";
                }, 2000);
            }, i * 200);
        });
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 =
                                document.querySelectorAll(".block_1 p");

                            blockP2.forEach((value, i) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(i + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                }, i * 500);
                            });
                        }
                    );
                }, 1000);
            });

            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "54px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "13px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "66px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "17px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "80px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "0";
                            el.style.textAlign = "center";
                            el2.style.fontSize = "23px";
                            el2.style.textAlign = "center";
                            el2.style.marginRight = "24px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "150px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "160px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.position = "relative";
                            el.style.left = "80px";
                            el.style.lineHeight = "215px";
                            el2.style.position = "relative";
                            el2.style.left = "80px";
                            el2.style.fontSize = "52px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 1:
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.lineHeight = "50px";
                            el.style.fontSize = "40px";
                            el2.style.fontSize = "16px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "49px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "18px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "90px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "28px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "100px";
                            el.style.lineHeight = "120px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "38px";
                            el.style.marginBottom = "0";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "135px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "150px";
                            el2.style.position = "relative";
                            el2.style.left = "40px";
                            el2.style.fontSize = "50px";
                            el.style.marginBottom = "0";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "55px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "28px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "105px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "55px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "125px";
                            el.style.lineHeight = "135px";
                            el.style.position = "relative";
                            el.style.left = "30px";
                            el2.style.position = "relative";
                            el2.style.left = "25px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "165px";
                            el.style.position = "relative";
                            el.style.left = "50px";
                            el.style.lineHeight = "175px";
                            el2.style.position = "relative";
                            el2.style.left = "60px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "85px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "105px";
                            el2.style.fontSize = "26px";
                            el.style.marginBottom = "0";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "90px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "21px";
                            el.style.marginBottom = "0";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "145px";
                            el.style.lineHeight = "155px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "40px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "235px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "265px";
                            el.style.lineHeight = "285px";
                            el2.style.fontSize = "75px";
                        }
                        break;
                    case 4:
                        
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "25px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "48px";
                            el.style.lineHeight = "60px";
                            el2.style.fontSize = "25px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "50px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "80px";
                            el.style.lineHeight = "95px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "105px";
                            el.style.lineHeight = "115px";
                            el2.style.fontSize = "60px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "140px";
                            el.style.lineHeight = "155px";
                            el2.style.fontSize = "80px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "58px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el.style.marginBottom = "0";
                            el2.style.fontSize = "25px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "70px";
                            el.style.lineHeight = "70px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "75px";
                            el.style.lineHeight = "95px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "30px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "110px";
                            el.style.lineHeight = "125px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "45px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                            el2.style.fontWeight = "400";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "155px";
                            el.style.lineHeight = "170px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "60px";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "215px";
                            el.style.fontWeight = "400";
                            el2.style.fontSize = "85px";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 375) {
                            el.style.fontSize = "40px";
                            el.style.lineHeight = "50px";
                            el2.style.fontSize = "20px";
                            el.style.marginBottom = "0";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "70px";
                            el2.style.fontSize = "23px";
                        } else if (width >= 414 && width < 600) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "75px";
                            el2.style.fontSize = "20px";
                        } else if (width >= 600 && width < 768) {
                            el.style.fontSize = "95px";
                            el.style.lineHeight = "110px";
                            el.style.marginLeft = "40px";
                            el.style.textAlign = "unset";
                            el2.style.fontSize = "35px";
                            el2.style.textAlign = "unset";
                            el2.style.marginLeft = "45px";
                            el.style.marginBottom = "0";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "140px";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "180px";
                            el.style.lineHeight = "195px";
                            el2.style.fontSize = "65px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 320 && width < 360) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 360 && width < 375) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 375 && width < 414) {
                            el.style.fontSize = "24px";
                            el.style.lineHeight = "24px";
                            el.style.marginBottom = "30px";
                        } else if (width >= 414 && width < 768) {
                            el.style.fontSize = "25px";
                            el.style.lineHeight = "60px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 768 && width < 1024) {
                            el.style.fontSize = "30px";
                            el.style.lineHeight = "120px";
                            el.style.marginBottom = "40px";
                        } else if (width >= 1024 && width < 1280) {
                            el.style.fontSize = "60px";
                            el.style.lineHeight = "150px";
                            el.style.marginBottom = "40px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);

    }
    handleFormSubmit(e) {
        console.log(e);
        e.preventDefault();
        this.setState({ flashBool: false, flashSuccess: false }, () => {
            let block = document.querySelector(".form_block_04");
            block.style.justifyContent = "flex-end";
            const { nom, prenom, email, sujet, message } = this.state;
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let test = re.test(String(email).toLowerCase());
            if (nom !== "" && message !== "" && email !== "") {
                if (test) {
                    axios
                        .post("/contact", {
                            nom: nom,
                            prenom: prenom,
                            email: email,
                            sujet: sujet,
                            message: message,
                        })
                        .then(
                            function (res) {
                                this.setState(
                                    {
                                        flash: "Votre message a bien été envoyé.",
                                        flashSuccess: true,
                                        nom: "",
                                        prenom: "",
                                        email: "",
                                        sujet: "",
                                        message: "",
                                    },
                                    () => {
                                        block.style.justifyContent =
                                            "space-between";
                                        this.forceUpdate();
                                    }
                                );
                            }.bind(this)
                        );
                } else {
                    this.setState(
                        {
                            flash: "Le format de votre adresse e-mail n'est pas valide.",
                            flashBool: true,
                        },
                        () => {
                            block.style.justifyContent = "space-between";
                        }
                    );
                }
            } else {
                this.setState(
                    {
                        flash: "Les champs nom, e-mail et message son obligatoire.",
                        flashBool: false,
                    },
                    () => {
                        block.style.justifyContent = "space-between";
                    }
                );
            }
        });
    }
    handleSlideClient(){
        this.closeMenu()
        setTimeout(() => {
            this.page(7);
        }, 1000);
    }
    hanldeSlideContact(e){
        this.closeMenu();
        setTimeout(() => {
            this.openContact()
        }, 1000);
    }
    render() {
        const { pages, socials, clients, pageNb, parts } = this.state;
        return (
            <div
                className="wrapperMobile"
            >
                <div className="bggruge"></div>
                <div className="item">
                    <header>
                        <div className="logo">
                            <img
                                src="img/retrokube.png"
                                onClick={this.firstPage}
                            />
                        </div>
                        <div className="menu" onClick={this.openMenu}>
                            <img src="img/menu.png" />
                        </div>
                    </header>
                        <div className="block_click_left" onClick={() => this.handleNextClick('prev')}>
                        <img src="img/arrow.svg" />
                        </div>
                    <div className="content">
                        <h2>retrokube</h2>
                        <h2>{this.state.baseline}</h2>
                        {pageNb === 7 ? (
                            <div className="wrap_block wrap_logo">
                                <div className="block_1 logo_block_1">
                                    {clients.map((value, index) => (
                                        <p key={index} >
                                            <img
                                            alt={value.alt}
                                                src={`storage/${value.logo}`}
                                            />
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className="wrap_block wrap">
                                <div
                                    className="block_1 block"
                                    dangerouslySetInnerHTML={{
                                        __html: this.state.block_01,
                                    }}
                                ></div>
                            </div>
                        )}
                    </div>
                        <div className="block_click_right" onClick={() => this.handleNextClick('next')}>
                        <img src="img/arrow.svg" />
                        </div>
                    <footer>
                        <div className="footerUp">
                            <div className="block">
                                <span>R</span>
                            </div>
                            <div className="social">
                                {socials.map((value, index) => (
                                    <div key={index} className="social_item">
                                        {value.titre === "contact" ? (
                                            <a
                                                onClick={(e) =>
                                                    this.openContact(e)
                                                }
                                            >
                                                <img
                                                    src={`storage/${value.image}`}
                                                />
                                            </a>
                                        ) : (
                                            <a
                                                href={value.Lien}
                                                target="_blank"
                                            >
                                                <img
                                                    src={`storage/${value.image}`}
                                                />
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="block">
                                <span>K</span>
                            </div>
                        </div>
                        <div className="bulletsBlock">
                            {pages.map((value, index) => (
                                <div
                                    key={index}
                                    className={`bullet ${
                                        index === pageNb ? "active" : ""
                                    }`}
                                ></div>
                            ))}
                        </div>
                    </footer>
                </div>
                <div className="itemContact">
                    <header>
                        <div className="logo">
                            <img
                                src="img/retrokube.png"
                                onClick={this.firstPage}
                            />
                        </div>
                        <div
                            className="closeContact"
                            onClick={this.closeContact}
                        >
                            <img src="img/Icon material-close-white.svg" />
                        </div>
                    </header>
                    <div className="flash_message_block">
                        {this.state.flashBool && (
                            <p className="message_flash">{this.state.flash}</p>
                        )}
                        {this.state.flashSuccess && (
                            <p className="message_flash_success">
                                {this.state.flash}
                            </p>
                        )}
                    </div>
                    <div className="content">
                        <h2>Contactez-nous</h2>
                        <h3>Présentez-nous votre projet.</h3>
                        <div className="block_contact_1 block">
                            <form>
                                <div className="form_block_01">
                                    <label>Nom</label>
                                    <input
                                        type="text"
                                        name="Nom"
                                        value={this.state.nom}
                                        onChange={(e) =>
                                            this.setState({
                                                nom: e.target.value,
                                            })
                                        }
                                    />
                                    <label>E-mail</label>
                                    <input
                                        type="text"
                                        name="email"
                                        value={this.state.email}
                                        onChange={(e) =>
                                            this.setState({
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="form_block_02">
                                    <label>Prénom</label>
                                    <input
                                        type="text"
                                        name="prenom"
                                        value={this.state.prenom}
                                        onChange={(e) =>
                                            this.setState({
                                                prenom: e.target.value,
                                            })
                                        }
                                    />
                                    <label>Sujet</label>
                                    <input
                                        type="text"
                                        name="sujet"
                                        value={this.state.sujet}
                                        onChange={(e) =>
                                            this.setState({
                                                sujet: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="form_block_03">
                                    <label>Message</label>
                                    <textarea
                                        value={this.state.message}
                                        onChange={(e) =>
                                            this.setState({
                                                message: e.target.value,
                                            })
                                        }
                                    ></textarea>
                                </div>
                                <div className="form_block_04">
                                    <button
                                        onClick={(e) =>
                                            this.handleFormSubmit(e)
                                        }
                                    >
                                        ENVOYER
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <footer>
                        <div className="footerUp">
                            <div className="block">
                                <span>R</span>
                            </div>
                            <div className="social">
                                {socials.map((value, index) => (
                                    <div key={index} className="social_item">
                                        {value.titre === "contact" ? (
                                            <></>
                                        ) : (
                                            <a
                                                href={value.Lien}
                                                target="_blank"
                                            >
                                                <img
                                                    src={`storage/${value.image}`}
                                                />
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="block">
                                <span>K</span>
                            </div>
                        </div>
                    </footer>
                </div>
                <div className="itemMenu">
                    <div className="block_menu">
                        <div className="close" onClick={this.closeMenu}>
                            <img src="img/Icon material-close.svg" />
                        </div>
                        <div className="content_menu">
                            <p onClick={this.handleSlideClient}>Nos clients</p>
                            <p onClick={this.hanldeSlideContact}>
                                Contactez-nous
                            </p>
                        </div>
                        <footer>
                            <div className="block_mentions">
                                <a href="/mentions-legales">Mentions légales</a>
                            </div>
                            <div className="social">
                                <div className="block_titre_social_menu">
                                    <p>
                                        Suivez l'agence
                                        <br />
                                        Sur les réseaux sociaux :
                                    </p>
                                </div>
                                <div className="socials">
                                    {socials.map((value, index) => (
                                        <div
                                            key={index}
                                            className="social_item"
                                        >
                                            {value.titre === "contact" ? (
                                                <></>
                                            ) : (
                                                <a
                                                    href={value.Lien}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={`storage/${value.image_black}`}
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="block_quarante">
                                <img src="img/LOGO-quarante-six-tampon.svg" />
                            </div>
                        </footer>
                    </div>
                </div>
            </div>
        );
    }
}

if (document.getElementById("mobile")) {
    let pages = $("#mobile").data("pages");
    let clients = $("#mobile").data("clients");
    let contact = $("#mobile").data("contact");
    let mention = $("#mobile").data("mention");
    let socials = $("#mobile").data("socials");
    let parts = $("#mobile").data("parts");
    ReactDOM.render(
        <Mobile
            pages={pages}
            clients={clients}
            socials={socials}
            mention={mention}
            contact={contact}
            parts={parts}
        />,
        document.getElementById("mobile")
    );
}
