import React from "react";
import ReactDOM from "react-dom";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import axios from "axios";
import { tsExpressionWithTypeArguments } from "@babel/types";

class TextScramble {
    constructor(el) {
        this.el = el;
        this.chars = "!<>-_—=+*^?#________rk";
        this.update = this.update.bind(this);
    }
    setText(newText) {
        const oldText = this.el.innerText;
        const length = Math.max(oldText.length, newText.length);
        const promise = new Promise((resolve) => (this.resolve = resolve));
        this.queue = [];
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || "";
            const to = newText[i] || "";
            const start = Math.floor(Math.random() * 40);
            const end = start + Math.floor(Math.random() * 40);
            this.queue.push({ from, to, start, end });
        }
        cancelAnimationFrame(this.frameRequest);
        this.frame = 0;
        this.update();
        return promise;
    }
    update() {
        let output = "";
        let complete = 0;
        for (let i = 0, n = this.queue.length; i < n; i++) {
            let { from, to, start, end, char } = this.queue[i];
            if (this.frame >= end) {
                complete++;
                output += to;
            } else if (this.frame >= start) {
                if (!char || Math.random() < 0.28) {
                    char = this.randomChar();
                    this.queue[i].char = char;
                }
                output += `<span class="dud">${char}</span>`;
            } else {
                output += from;
            }
        }
        this.el.innerHTML = output;
        if (complete === this.queue.length) {
            this.resolve();
        } else {
            this.frameRequest = requestAnimationFrame(this.update);
            this.frame++;
        }
    }
    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)];
    }
}

export default class Front extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pages: this.props.pages,
            parts: this.props.parts,
            clients: this.props.clients,
            contact: this.props.contact,
            mention: this.props.mention,
            socials: this.props.socials,
            titre: this.props.pages[0].title,
            baseline: this.props.pages[0].baseline,
            block_01: this.props.pages[0].block_01,
            block_02: this.props.pages[0].block_02,
            pageNb: 0,
            phrases: [],
            counter: 0,
            size: 20,
            openMenu: false,
            nom: "",
            prenom: "",
            email: "",
            sujet: "",
            message: "",
            flash: "",
            flashBool: false,
            flashSuccess: false,
            width: window.innerWidth,
        };
        this.openContact = this.openContact.bind(this);
        this.firstPage = this.firstPage.bind(this);
        this.handlePrevSlide = this.handlePrevSlide.bind(this);
        this.handleNextSlide = this.handleNextSlide.bind(this);
        this.handlePrevScroll = this.handlePrevScroll.bind(this);
        this.handleNextScroll = this.handleNextScroll.bind(this);
        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.handleSlideClient = this.handleSlideClient.bind(this);
        this.hanldeSlideContact = this.hanldeSlideContact.bind(this);
        this.closeContact = this.closeContact.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleScrollPrevSlide = this.handleScrollPrevSlide.bind(this);
        this.handleScrollNextSlide = this.handleScrollNextSlide.bind(this);
    }
    async componentDidMount() {
        let { phrases, pages, counter } = this.state;
        const blockP = document.querySelectorAll(".block_1 p");
        const el = document.querySelector(".content h2");
        const fx = new TextScramble(el);
        phrases = ["retrokube", "retrokube"];
        setTimeout(() => {
           
                blockP.forEach((value, i) => {
                    setTimeout(() => {
                        value.style.opacity = "1";
                        value.classList.add(
                            "animate__animated",
                            "animate__fadeInRight"
                        );
                    }, i * 500);
                });
            counter = (counter + 1) % phrases.length;
            this.setState(
                {
                    counter,
                },
                () => {
                    phrases = [];
                    pages.map((value) => {
                        phrases.push(value.title);
                    });
                    let links = document.querySelectorAll(
                        "#front .item .content .wrap_block.wrap .block_1 p"
                    );
                    links.forEach((value, index) => {
                        value.addEventListener("click", () => {
                            this.page(index + 1);
                        });
                    });
                    this.setState({ phrases });
                }
            );
        }, 500);
    }
    page(id) {
        let { phrases, counter, pages, pageNb, width } = this.state;

        pageNb = id;
        counter = id;

        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".block_1 p");
        const block2 = document.querySelector(".block_2");
        const point = document.querySelector("#trame");

        const body = document.querySelector("#front");
        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".item .bggruge");

        block2.style.opacity = "0";
        this.animeTextUnder();
        switch (pageNb) {
            case 0:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame01.svg";
                break;
            case 1:
                body.style.background = "#22EABF";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame02.svg";
                break;
            case 2:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame03.svg";
                break;
            case 3:
                body.style.background = "#EF6949";
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame04.svg";
                break;
            case 4:
                body.style.background = "#8C4BF4";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame05.svg";
                break;
            case 5:
                body.style.background = "#E33B8F";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame06.svg";
                break;
            case 6:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame07.svg";
                break;
            case 7:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                point.src = "img/trame08.svg";
                break;
            default:
                break;
        }
        el2.style.opacity = "0";
        if (pageNb == 0) {
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                        break;
                    case 1:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 2:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                        break;
                    case 3:
                        itembg.style.backgroundImage =
                        "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 4:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;
                    case 5:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 6:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 7:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;

                    default:
                        break;
                }
            }, 1000);
        } else {
            blockP.forEach((value, i) => {
                setTimeout(() => {
                    value.classList.remove(
                        "animate__animated",
                        "animate__fadeInRight"
                    );
                    value.classList.add(
                        "animate__animated",
                        "animate__fadeOutLeft"
                    );
                    setTimeout(() => {
                        switch (pageNb) {
                            case 0:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                                break;
                            case 1:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                                
                                
                                break;
                            case 2:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                                break;
                            case 3:
                                itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                
                                
                                break;
                            case 4:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                                
                                
                                break;
                            case 5:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                
                                
                                break;
                            case 6:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                                
                                
                                break;
                            case 7:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                        
                                
                                break;

                            default:
                                break;
                        }
                    }, 1000);
                    setTimeout(() => {
                        value.style.opacity = "0";
                        itembgAfter.style.opacity = "0";
                        itembgAfter.style.animation = "unset";
                    }, 2000);
                }, i * 200);
            });
        }
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 = document.querySelectorAll(".block_1 p");
                            const block2 = document.querySelector(".block_2");

                            blockP2.forEach((value, index) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(index + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                    setTimeout(() => {
                                        block2.style.opacity = "1";
                                        let contact = document.querySelector('.block_2 .contact');
                                        if(contact !== null){
                                            contact.addEventListener('click',() => {
                                                this.openContact();
                                            })
                                        }
                                    }, 1500);
                                }, index * 500);
                            });
                        }
                    );
                }, 1000);
            });
            setTimeout(() => {
                
                switch (pageNb) {
                    case 0:
                        
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "50px";
                        }

                        break;
                    case 1:
                        
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "118px";
                            el.style.fontSize = "104px";
                            el2.style.fontSize = "37px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "188px";
                            el.style.fontSize = "164px";
                            el2.style.fontSize = "42px";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "178px";
                            el.style.lineHeight = "178px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "38px";
                        }
                        break;
                    case 4:
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1366) {
                            el.style.fontSize = "146px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "156px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "176px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "152px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1920) {
                            el.style.fontSize = "212px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "68px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "160px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "44px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);
    }
    firstPage() {
        this.page(0);
    }
    openMenu() {
        const { width } = this.state;
        let menu = document.querySelector(".itemMenu");
        let menuBlock = document.querySelector(".itemMenu .block_menu");
        menu.style.display = "block";
        setTimeout(() => {
            if (width >= 1280 && width < 1360) {
                menu.style.width = "1100px";
            } else if (width >= 1366 && width < 1400) {
                menu.style.width = "1100px";
            } else if (width >= 1400 && width < 1440) {
                menu.style.width = "1100px";
            } else if (width >= 1440 && width < 1600) {
                menu.style.width = "1100px";
            } else if (width >= 1600 && width < 1920) {
                menu.style.width = "1200px";
            } else if (width >= 1920) {
                menu.style.width = "1470px";
            }
            menu.style.opacity = "1";
            menu.style.padding = "30px 50px 30px 60px";
            setTimeout(() => {
                menuBlock.style.opacity = "1";
            }, 700);
        }, 200);
    }
    closeMenu() {
        let menu = document.querySelector(".itemMenu");
        let menuBlock = document.querySelector(".itemMenu .block_menu");
        menuBlock.style.opacity = "0";
        setTimeout(() => {
            menu.style.width = "0";
            menu.style.padding = "0px";
            setTimeout(() => {
                menu.style.opacity = "0";
                menu.style.display = "none";
            }, 700);
        }, 500);
    }
    handleSlideClient() {
        this.closeMenu();
        setTimeout(() => {
            this.page(7);
        }, 1000);
    }
    hanldeSlideContact(e) {
        this.openContact();
    }
    closeContact() {
        const { pageNb } = this.state;
        this.setState(
            { flashBool: false, flashSuccess: false, flash: "" },
            () => {
                let block = document.querySelector(".form_block_04");
                block.style.justifyContent = "flex-end";
                const body = document.querySelector("#front");
                switch (pageNb) {
                    case 0:
                        body.style.background = "#FFD802";
                        break;
                    case 1:
                        body.style.background = "#22EABF";
                        break;
                    case 2:
                        body.style.background = "#3C82E2";
                        break;
                    case 3:
                        body.style.background = "#EF6949";
                        break;
                    case 4:
                        body.style.background = "#8C4BF4";
                        break;
                    case 5:
                        body.style.background = "#E33B8F";
                        break;
                    case 6:
                        body.style.background = "#FFD802";
                        break;
                    case 7:
                        body.style.background = "#3C82E2";
                        break;
                    default:
                        break;
                }
                let contact = document.querySelector(".itemContact");
                let contactBlock = document.querySelector(
                    ".itemContact .block_contact"
                );
                contactBlock.style.opacity = "0";
                setTimeout(() => {
                    contact.style.width = "0";
                    setTimeout(() => {
                        contact.style.opacity = "0";
                    }, 700);
                }, 500);
            }
        );
    }
    openContact() {
        const { width } = this.state;
        const body = document.querySelector("#front");
        let contact = document.querySelector(".itemContact");
        let contactBlock = document.querySelector(
            ".itemContact .block_contact"
        );
        contact.style.display = "block";
        setTimeout(() => {
            if (width >= 1280 && width < 1360) {
                contact.style.width = "1100px";
            } else if (width >= 1366 && width < 1400) {
                contact.style.width = "1100px";
            } else if (width >= 1400 && width < 1440) {
                contact.style.width = "1100px";
            } else if (width >= 1440 && width < 1600) {
                contact.style.width = "1100px";
            } else if (width >= 1600 && width < 1920) {
                contact.style.width = "1200px";
            } else if (width >= 1920) {
                contact.style.width = "1470px";
            }
            contact.style.opacity = "1";
            body.style.background = "#EF6949";
            setTimeout(() => {
                contactBlock.style.opacity = "1";
                this.closeMenu();
            }, 700);
        }, 100);
    }
    handlePrevSlide() {
        this.handlePrevScroll();
    }
    handleNextSlide() {
        this.handleNextScroll();
    }
    handleScrollPrevSlide(e,click){
        if(click === true){
            this.handlePrevScroll(e);
        }else{
            setTimeout(() => {
                this.handlePrevScroll(e);
            }, 2000);
        }
    }
    handleScrollNextSlide(e,click){
        if(click === true){
            this.handleNextScroll(e);
        }else{
            setTimeout(() => {
                this.handleNextScroll(e);
            }, 2000);
        }
    }
    handlePrevScroll(e) {
        let { phrases, counter, pages, pageNb, width } = this.state;
        if (pageNb === 0) {
            pageNb = 7;
        } else {
            pageNb = pageNb - 1;
        }

        if (pageNb === 0) {
            counter = 0;
        } else if (pageNb === 1) {
            counter = 1;
        } else if (pageNb === 2) {
            counter = 2;
        } else if (pageNb === 3) {
            counter = 3;
        } else if (pageNb === 4) {
            counter = 4;
        } else if (pageNb === 5) {
            counter = 5;
        } else if (pageNb === 6) {
            counter = 6;
        } else if (pageNb === 7) {
            counter = 7;
        }
        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".block_1 p");
        const block2 = document.querySelector(".block_2");
        const point = document.querySelector("#trame");

        const body = document.querySelector("#front");
        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".item .bggruge");

        block2.style.opacity = "0";
        this.animeTextUnder();
        switch (pageNb) {
            case 0:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame01.svg";
                break;
            case 1:
                body.style.background = "#22EABF";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame02.svg";
                break;
            case 2:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame03.svg";
                break;
            case 3:
                body.style.background = "#EF6949";
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame04.svg";
                break;
            case 4:
                body.style.background = "#8C4BF4";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame05.svg";
                break;
            case 5:
                body.style.background = "#E33B8F";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame06.svg";
                break;
            case 6:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame07.svg";
                break;
            case 7:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                point.src = "img/trame08.svg";
                break;
            default:
                break;
        }

        el2.style.opacity = "0";
        blockP.forEach((value, i) => {
            setTimeout(() => {
                value.classList.remove(
                    "animate__animated",
                    "animate__fadeInRight"
                );
                value.classList.add(
                    "animate__animated",
                    "animate__fadeOutLeft"
                );
                setTimeout(() => {
                    
                    switch (pageNb) {
                        case 0:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                            
                            break;
                        case 1:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                                
                            break;
                        case 2:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                                
                            break;
                        case 3:
                            itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                
                            break;
                        case 4:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                                
                            break;
                        case 5:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                
                            break;
                        case 6:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                                
                            break;
                        case 7:
                            itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                                
                            break;

                        default:
                            break;
                    }
                }, 1000);
                setTimeout(() => {
                    value.style.opacity = "0";
                    itembgAfter.style.opacity = "0";
                    itembgAfter.style.animation = "unset";
                }, 2000);
            }, i * 200);
        });
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 =
                                document.querySelectorAll(".block_1 p");
                            const block2 = document.querySelector(".block_2");

                            blockP2.forEach((value, index) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(index + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                    setTimeout(() => {
                                        block2.style.opacity = "1";
                                        let contact = document.querySelector('.block_2 .contact');
                                        if(contact !== null){
                                            contact.addEventListener('click',() => {
                                                this.openContact();
                                            })
                                        }
                                    }, 1500);
                                }, index * 500);
                            });
                        }
                    );
                }, 1000);
            });

            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "50px";
                        }

                        break;
                    case 1:
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "118px";
                            el.style.fontSize = "104px";
                            el2.style.fontSize = "37px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "188px";
                            el.style.fontSize = "164px";
                            el2.style.fontSize = "42px";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "178px";
                            el.style.lineHeight = "178px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "38px";
                        }
                        break;
                    case 4:
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1366) {
                            el.style.fontSize = "146px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "156px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "176px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "152px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1920) {
                            el.style.fontSize = "212px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "68px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "160px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "44px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);
    }
    handleNextScroll(e) {            
        let { phrases, counter, pages, pageNb, width } = this.state;
        if (pageNb === 7) {
            pageNb = 0;
        } else {
            pageNb = pageNb + 1;
        }

        if (pageNb === 0) {
            counter = 0;
        } else if (pageNb === 1) {
            counter = 1;
        } else if (pageNb === 2) {
            counter = 2;
        } else if (pageNb === 3) {
            counter = 3;
        } else if (pageNb === 4) {
            counter = 4;
        } else if (pageNb === 5) {
            counter = 5;
        } else if (pageNb === 6) {
            counter = 6;
        } else if (pageNb === 7) {
            counter = 7;
        }
        const el = document.querySelector(".content h2");
        const el2 = document.querySelector(".content h3");

        const blockP = document.querySelectorAll(".block_1 p");
        const block2 = document.querySelector(".block_2");
        const point = document.querySelector("#trame");

        const body = document.querySelector("#front");
        const itembg = document.querySelector(".item");
        const itembgAfter = document.querySelector(".item .bggruge");

        block2.style.opacity = "0";
        this.animeTextUnder();
        switch (pageNb) {
            case 0:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame01.svg";
                break;
            case 1:
                body.style.background = "#22EABF";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame02.svg";
                break;
            case 2:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame03.svg";
                break;
            case 3:
                body.style.background = "#EF6949";
                itembgAfter.style.backgroundImage =
                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame04.svg";
                break;
            case 4:
                body.style.background = "#8C4BF4";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame05.svg";
                break;
            case 5:
                body.style.background = "#E33B8F";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame06.svg";
                break;
            case 6:
                body.style.background = "#FFD802";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                itembgAfter.style.animation = "fade 2s";
                point.src = "img/trame07.svg";
                break;
            case 7:
                body.style.background = "#3C82E2";
                itembgAfter.style.backgroundImage =
                    "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                itembgAfter.style.animation = "fade 1.5s";
                point.src = "img/trame08.svg";
                break;
            default:
                break;
        }
        el2.style.opacity = "0";
        if (pageNb == 0) {
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                        break;
                    case 1:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 2:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                        break;
                    case 3:
                        itembg.style.backgroundImage =
                        "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 4:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;
                    case 5:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                        break;
                    case 6:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                        break;
                    case 7:
                        itembg.style.backgroundImage =
                            "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                        break;

                    default:
                        break;
                }
            }, 1000);
        } else {
            blockP.forEach((value, i) => {
                setTimeout(() => {
                    value.classList.remove(
                        "animate__animated",
                        "animate__fadeInRight"
                    );
                    value.classList.add(
                        "animate__animated",
                        "animate__fadeOutLeft"
                    );
                    setTimeout(() => {
                       
                        switch (pageNb) {
                            case 0:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#F8D949 0%,#E33B8F 80%, #E33B8F 100%)";
                                
                                break;
                            case 1:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#74E6C1 0%,#8352EB 80%, #8352EB 100%)";
                                   
                                break;
                            case 2:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#DD7153 80%, #DD7153 100%)";
                                    
                                break;
                            case 3:
                                itembg.style.backgroundImage =
                                "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                    
                                break;
                            case 4:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#5082DB 0%,#74E6C1 80%, #74E6C1 100%)";
                                    
                                break;
                            case 5:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#E74195 0%,#5082DB 80%, #5082DB 100%)";
                                   
                                break;
                            case 6:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#FFD802 0%,#8352EB 80%, #8352EB 100%)";
                                    
                                break;
                            case 7:
                                itembg.style.backgroundImage =
                                    "radial-gradient(farthest-corner at 140px 630px,#3C82E2 0%,#74E6C1 80%, #74E6C1 100%)";
                                    
                                break;

                            default:
                                break;
                        }
                    }, 1000);
                    setTimeout(() => {
                        value.style.opacity = "0";
                        itembgAfter.style.opacity = "0";
                        itembgAfter.style.animation = "unset";
                    }, 2000);
                }, i * 200);
            });
        }
        const fx = new TextScramble(el);
        setTimeout(() => {
            fx.setText(phrases[counter]).then(() => {
                this.setState({ baseline: pages[pageNb].baseline });
                el2.style.opacity = "1";
                setTimeout(() => {
                    this.setState(
                        {
                            block_01: pages[pageNb].block_01,
                            block_02: pages[pageNb].block_02,
                        },
                        () => {
                            const blockP2 =
                                document.querySelectorAll(".block_1 p");
                            const block2 = document.querySelector(".block_2");

                            blockP2.forEach((value, index) => {
                                setTimeout(() => {
                                    value.classList.remove(
                                        "animate__animated",
                                        "animate__fadeOutLeft"
                                    );
                                    value.classList.add(
                                        "animate__animated",
                                        "animate__fadeInRight"
                                    );
                                    setTimeout(() => {
                                        switch (pageNb) {
                                            case 0:
                                                value.addEventListener("click", () => {
                                                    value.style.cursor = "pointer";
                                                    this.page(index + 1);
                                                });
                                                break;
                                        
                                            default:
                                                value.style.cursor = "auto";
                                                break;
                                        }
                                    }, 2000);
                                    setTimeout(() => {
                                        block2.style.opacity = "1";
                                        let contact = document.querySelector('.block_2 .contact');
                                        if(contact !== null){
                                            contact.addEventListener('click',() => {
                                                this.openContact();
                                            })
                                        }
                                    }, 1500);
                                }, index * 500);
                            });
                        }
                    );
                }, 1000);
            });
            setTimeout(() => {
                switch (pageNb) {
                    case 0:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "158px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "50px";
                        }

                        break;
                    case 1:
                        el.style.fontFamily = "Corben";
                        el2.style.fontFamily = "Corben";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "168px";
                            el.style.fontSize = "114px";
                            el2.style.fontSize = "36px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "118px";
                            el.style.fontSize = "104px";
                            el2.style.fontSize = "37px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "188px";
                            el.style.fontSize = "164px";
                            el2.style.fontSize = "42px";
                        }
                        break;
                    case 2:
                        el.style.fontFamily = "Crimson Text";
                        el2.style.fontFamily = "Crimson Text";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "128px";
                            el.style.lineHeight = "98px";
                            el2.style.fontSize = "40px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "205px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 3:
                        el.style.fontFamily = "Poppins";
                        el2.style.fontFamily = "Poppins";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "168px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "178px";
                            el.style.lineHeight = "178px";
                            el2.style.fontSize = "30px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "218px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "38px";
                        }
                        break;
                    case 4:
                        el.style.fontFamily = "Kreon";
                        el2.style.fontFamily = "Kreon";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "146px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "156px";
                            el.style.lineHeight = "168px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "130px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "46px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "176px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "56px";
                        }
                        break;
                    case 5:
                        el.style.fontFamily = "Mr Dafoe";
                        el2.style.fontFamily = "Mr Dafoe";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                            el2.style.fontSize = "48px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "172px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "152px";
                            el.style.lineHeight = "138px";
                            el2.style.fontSize = "48px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        } else if (width >= 1920) {
                            el.style.fontSize = "212px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "68px";
                            el.style.fontWeight = "400";
                            el2.style.fontWeight = "400";
                        }
                        break;
                    case 6:
                        el.style.fontFamily = "Exo";
                        el2.style.fontFamily = "Exo";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.fontSize = "150px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.fontSize = "160px";
                            el.style.lineHeight = "158px";
                            el2.style.fontSize = "44px";
                        } else if (width >= 1920) {
                            el.style.fontSize = "200px";
                            el.style.lineHeight = "188px";
                            el2.style.fontSize = "44px";
                        }
                        break;
                    case 7:
                        el.style.fontFamily = "kanitbold";
                        el2.style.fontFamily = "kanitbold_italic";
                        el.style.letterSpacing = "unset";
                        if (width >= 1280 && width < 1360) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1366 && width < 1400) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1400 && width < 1440) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1440 && width < 1600) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1600 && width < 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        } else if (width >= 1920) {
                            el.style.lineHeight = "108px";
                            el.style.fontSize = "56px";
                            el2.style.fontSize = "50px";
                        }
                        break;
                    default:
                        break;
                }
            }, 700);
            this.setState({
                phrases,
                counter,
                pageNb,
            });
        }, 500);
    }
    animeTextUnder() {
        const rkTop = document.querySelector(".name_rk_top");
        const rkBottom = document.querySelector(".name_rk_bottom");
        rkTop.style.top = "-50vh";
        rkBottom.style.top = "150vh";
        setTimeout(() => {
            rkTop.style.transition = "unset";
            rkBottom.style.transition = "unset";
        }, 1800);
        setTimeout(() => {
            rkTop.style.top = "50vh";
            rkBottom.style.top = "50vh";
            setTimeout(() => {
                rkTop.style.transition = "top 1700ms";
                rkBottom.style.transition = "top 1700ms";
            }, 1000);
        }, 2000);
    }
    handleFormSubmit(e) {
        e.preventDefault();
        this.setState({ flashBool: false, flashSuccess: false }, () => {
            let block = document.querySelector(".form_block_04");
            block.style.justifyContent = "flex-end";
            const { nom, prenom, email, sujet, message } = this.state;
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let test = re.test(String(email).toLowerCase());
            if (nom !== "" && message !== "" && email !== "") {
                if (test) {
                    axios
                        .post("/contact", {
                            nom: nom,
                            prenom: prenom,
                            email: email,
                            sujet: sujet,
                            message: message,
                        })
                        .then(
                            function (res) {
                                this.setState(
                                    {
                                        flash: "Votre message a bien été envoyé.",
                                        flashSuccess: true,
                                        nom: "",
                                        prenom: "",
                                        email: "",
                                        sujet: "",
                                        message: "",
                                    },
                                    () => {
                                        block.style.justifyContent =
                                            "space-between";
                                        this.forceUpdate();
                                    }
                                );
                            }.bind(this)
                        );
                } else {
                    this.setState(
                        {
                            flash: "Le format de votre adresse e-mail n'est pas valide.",
                            flashBool: true,
                        },
                        () => {
                            block.style.justifyContent = "space-between";
                        }
                    );
                }
            } else {
                this.setState(
                    {
                        flash: "Les champs nom, e-mail et message son obligatoire.",
                        flashBool: false,
                    },
                    () => {
                        block.style.justifyContent = "space-between";
                    }
                );
            }
        });
    }
    render() {
        const { pages, socials, clients, pageNb, parts } = this.state;
        return (
            <>
                <ReactScrollWheelHandler
                    upHandler={(e) => this.handleScrollPrevSlide(e,false)}
                    downHandler={(e) => this.handleScrollNextSlide(e,false)}
                >
                    <div className="bulletsBlock">
                        {pages.map((value, index) => (
                            <div
                                key={index}
                                className={`bullet ${
                                    index === pageNb ? "active" : ""
                                }`}
                            ></div>
                        ))}
                    </div>
                    <div className="prev" onClick={this.handlePrevSlide}>
                        <img src="img/arrow.svg" />
                    </div>
                    <div className="name_rk_top name_rk">
                        <h2>retrokube</h2>
                    </div>

                    <div className="item">
                        <div className="itemMenu">
                            <div className="block_menu">
                                <div className="close" onClick={this.closeMenu}>
                                    <img src="img/Icon material-close.svg" />
                                </div>
                                <div className="content_menu">
                                    <p onClick={this.handleSlideClient}>
                                        Nos clients
                                    </p>
                                    <p onClick={this.hanldeSlideContact}>
                                        Contactez-nous
                                    </p>
                                </div>
                                <footer>
                                    <div className="block_mentions">
                                        <a href="/mentions-legales">
                                            Mentions légales
                                        </a>
                                    </div>

                                    <div className="social">
                                        <div className="block_titre_social_menu">
                                            <p>
                                                Suivez l'agence
                                                <br />
                                                Sur les réseaux sociaux :
                                            </p>
                                        </div>
                                        {socials.map((value, index) => (
                                            <div
                                                key={index}
                                                className="social_item"
                                            >
                                                {value.titre === "contact" ? (
                                                    <></>
                                                ) : (
                                                    <a
                                                        href={value.Lien}
                                                        target="_blank"
                                                    >
                                                        <img
                                                            src={`storage/${value.image_black}`}
                                                        />
                                                    </a>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="block_quarante">
                                        <img src="img/LOGO-quarante-six-tampon.svg" />
                                    </div>
                                </footer>
                            </div>
                        </div>
                        <div className="itemContact">
                            <div className="block_contact">
                                <div className="point">
                                    <img src="img/trame06.svg" />
                                </div>
                                <div className="header">
                                    <div className="logo">
                                        <img
                                            src="img/retrokube.png"
                                            onClick={this.firstPage}
                                        />
                                        <div className="social">
                                            {socials.map((value, index) => (
                                                <div
                                                    key={index}
                                                    className="social_item"
                                                >
                                                    {value.titre ===
                                                    "contact" ? (
                                                        <a
                                                            onClick={(e) =>
                                                                this.openContact(
                                                                    e
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={`storage/${value.image}`}
                                                            />
                                                        </a>
                                                    ) : (
                                                        <a
                                                            href={value.Lien}
                                                            target="_blank"
                                                        >
                                                            <img
                                                                src={`storage/${value.image}`}
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div
                                        className="closeContact"
                                        onClick={this.closeContact}
                                    >
                                        <img src="img/Icon material-close-white.svg" />
                                    </div>
                                </div>
                                <div className="content_contact">
                                    <div className="wrap_block wrap">
                                        <h2>Contactez-nous</h2>
                                        <h3>Présentez-nous votre projet.</h3>
                                        <div className="block_contact_1 block">
                                            <form>
                                                <div className="form_block_01">
                                                    <label>Nom</label>
                                                    <input
                                                        type="text"
                                                        name="Nom"
                                                        value={this.state.nom}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                nom: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                    <label>E-mail</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                email: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="form_block_02">
                                                    <label>Prénom</label>
                                                    <input
                                                        type="text"
                                                        name="prenom"
                                                        value={
                                                            this.state.prenom
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                prenom: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                    <label>Sujet</label>
                                                    <input
                                                        type="text"
                                                        name="sujet"
                                                        value={this.state.sujet}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                sujet: e.target
                                                                    .value,
                                                            })
                                                        }
                                                    />
                                                </div>
                                                <div className="form_block_03">
                                                    <label>Message</label>
                                                    <textarea
                                                        value={
                                                            this.state.message
                                                        }
                                                        onChange={(e) =>
                                                            this.setState({
                                                                message:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                    ></textarea>
                                                </div>
                                                <div className="form_block_04">
                                                    {this.state.flashBool && (
                                                        <p className="message_flash">
                                                            {this.state.flash}
                                                        </p>
                                                    )}
                                                    {this.state
                                                        .flashSuccess && (
                                                        <p className="message_flash_success">
                                                            {this.state.flash}
                                                        </p>
                                                    )}
                                                    <button
                                                        onClick={(e) =>
                                                            this.handleFormSubmit(
                                                                e
                                                            )
                                                        }
                                                    >
                                                        ENVOYER
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="block_contact_2 block">
                                            <div className="adresse">
                                                <h3>Retrokube</h3>
                                                <p>
                                                    46 rue de Talleyrand
                                                    <br />
                                                    51100 REIMS - FRANCE
                                                    <br />T / +33 (0) 3 52 62
                                                    0000
                                                </p>
                                            </div>
                                            <div className="quarante">
                                                <p>
                                                    Membre du collectif{" "}
                                                    <span>
                                                        <img src="img/LOGO-quarante-six-tampon_blanc.svg" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="parts">
                                                <h4>Nos partenaires</h4>
                                                <div className="block_parts">
                                                    {parts.map(
                                                        (value, index) => (
                                                            <div
                                                                key={index}
                                                                className="part_solo"
                                                            >
                                                                <img
                                                                    src={`storage/${value.logo}`}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="footer">
                                    <div className="block">
                                        <span>R</span>
                                    </div>
                                    <div className="block">
                                        <span>K</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bggruge"></div>
                        <div className="point">
                            <img id="trame" src="img/trame01.svg" />
                        </div>
                        <div className="header">
                            <div className="logo">
                                <img
                                    src="img/retrokube.png"
                                    onClick={this.firstPage}
                                />
                                <div className="social">
                                    {socials.map((value, index) => (
                                        <div
                                            key={index}
                                            className="social_item"
                                        >
                                            {value.titre === "contact" ? (
                                                <a
                                                    onClick={(e) =>
                                                        this.openContact(e)
                                                    }
                                                >
                                                    <img
                                                        src={`storage/${value.image}`}
                                                    />
                                                </a>
                                            ) : (
                                                <a
                                                    href={value.Lien}
                                                    target="_blank"
                                                >
                                                    <img
                                                        src={`storage/${value.image}`}
                                                    />
                                                </a>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="menu" onClick={this.openMenu}>
                                <img src="img/menu.png" />
                            </div>
                        </div>
                        <div className="content silde_01">
                            <h2>retrokube</h2>
                            <h3>{this.state.baseline}</h3>
                            {pageNb === 7 ? (
                                <div className="wrap_block wrap_logo">
                                    <div className="block_1 logo_block_1">
                                        {clients.map((value, index) => (
                                            <p key={index}>
                                                <img
                                                    src={`storage/${value.logo}`}
                                                />
                                            </p>
                                        ))}
                                    </div>
                                    <div className="block_2 logo_block_2"></div>
                                </div>
                            ) : (
                                <div className="wrap_block wrap">
                                    <div
                                        className="block_1 block"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.block_01,
                                        }}
                                    ></div>
                                    <div
                                        className="block_2 block"
                                        dangerouslySetInnerHTML={{
                                            __html: this.state.block_02,
                                        }}
                                    ></div>
                                </div>
                            )}
                        </div>
                        <div className="footer">
                            <div className="block">
                                <span>R</span>
                            </div>
                            <div className="block">
                                <img src="img/mouse.svg" />
                            </div>
                            <div className="block">
                                <span>K</span>
                            </div>
                        </div>
                    </div>
                    <div className="name_rk_bottom name_rk">
                        <h2>retrokube</h2>
                    </div>
                    <div className="next" onClick={this.handleNextSlide}>
                        <img src="img/arrow.svg" />
                    </div>
                </ReactScrollWheelHandler>
            </>
        );
    }
}

if (document.getElementById("front")) {
    let pages = $("#front").data("pages");
    let clients = $("#front").data("clients");
    let contact = $("#front").data("contact");
    let mention = $("#front").data("mention");
    let socials = $("#front").data("socials");
    let parts = $("#front").data("parts");
    ReactDOM.render(
        <Front
            pages={pages}
            clients={clients}
            socials={socials}
            mention={mention}
            contact={contact}
            parts={parts}
        />,
        document.getElementById("front")
    );
}
